<template>
  <div>
    <transition mode="out-in" name="fade-three">
      <router-view/>
    </transition>
    <van-tabbar v-model="active" ruote safe-area-inset-bottom z-index='1000'>
      <van-tabbar-item v-for="item in tabBarArr" :key="item.id" :class="{ bigItem: item.routeName == $route.name }" :name="item.id" :to="item.path"
                       class="tab-item" replace>
        <template #icon="props">
          <img :src="props.active ? item.selected : item.normal"/>
        </template>
        {{ $t(item.name) }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "tabbar",
  data() {
    return {
      show: true,
      tabBarArr: [
        {
          id: 0, //ID号
          name: "首页", //名称
          path: "/",
          routeName: "/", //路由名称
          normal: require("@/assets/img/tab1.png"), //未选中时图标
          selected: require("@/assets/img/tab2.png"), //选中时图标
        },
        {
          id: 1,
          name: "交易大厅",
          path: "/market",
          routeName: "market",
          normal: require("@/assets/img/tab3.png"),
          selected: require("@/assets/img/tab4.png"),
        },
        {
          id: 2,
          name: "游戏",
          path: "/game",
          routeName: "game",
          normal: require("@/assets/img/tab5.png"),
          selected: require("@/assets/img/tab6.png"),
        },
        {
          id: 3,
          name: "团队",
          path: "/team",
          routeName: "team",
          normal: require("@/assets/img/tab7.png"),
          selected: require("@/assets/img/tab8.png"),
        },
        {
          id: 4,
          name: "我的",
          path: "/mine",
          routeName: "mine",
          normal: require("@/assets/img/tab9.png"),
          selected: require("@/assets/img/tab10.png"),
        }
      ],
      isShowBottom: true, //显示或者隐藏footer
      documentHeight: document.documentElement.clientHeight, //默认屏幕高度
    }
  },
  computed: {
    active: {
      get: function () {
        let obj = this.tabBarArr.find(val => val.routeName == this.$route.name)
        return obj.id || 0
      },
      set: function (data) {
      },
    }
  },
  methods: {},
  mounted() {
    window.onresize = () => {
      return (() => {
        if (this.documentHeight > document.documentElement.clientHeight) {
          this.isShowBottom = false
        } else {
          this.isShowBottom = true
        }
      })()
    }
  },
}
</script>
<style lang="less" scoped>
.fade-three-enter-active,
.fade-three-leave-active {
  transition: opacity 0.15s;
}

.fade-three-enter,
.fade-three-leave-to
  /* .fade-three-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/deep/ .van-tabbar {
  background-color: #343638;

  .van-tabbar-item {
    .van-tabbar-item__text {
      color: @tab_in_color;
    }
  }

  .van-tabbar-item--active {
    background-color: transparent;

    .van-tabbar-item__text {
      color: @tab_ac_color;
    }
  }
}
</style>
